import React from 'react';

import Layout from '../../components/layout/layout';
import SEO from '../../components/seo/seo';
import ContractorFullSignup from '../../components/signup/contractorFullSignup';
import MainWrapper from '../../components/ui/MainWrapper'

const ContractorSignupPage = () => {
  return (
    <MainWrapper>
      <Layout backButton="/signup">
        <SEO title="Contractor signup" keywords={['signup', 'contractor']} canonicalUrl="https://app.fairmarkethome.com/signup/contractor" />
        <ContractorFullSignup accountType="Contractor" />
      </Layout>
    </MainWrapper>
  );
}

export default ContractorSignupPage;
